<template>
    <div 
        id="layout"
        class="layout" 
    >
        <NuxtLoadingIndicator
            :height="2"
            color="var(--color-primary)"
        />
        <a
            href="#main"
            class="layout__skiplink button"
        >
            {{ t('skiplinks.content') }}
        </a>
        <a
            href="#footer"
            class="layout__skiplink button button--secondary"
        >
            {{ t('skiplinks.footer') }}
        </a>

        <TheHeader class="layout__header" />

        <div
            id="main"
            ref="main"
            class="layout__main"
            tabindex="-1"
        >
            <slot />
        </div>

        <div class="curtain" />

        <div class="section__footer">
            <TheNewsletterSignup v-show="showNewsletterSubscribe" />
            <TheFooter
                id="footer"
                class="layout__footer"
            />
        </div>
        <CustomCursor
            :throttle-delay="500"
            :hoverable-elements="hoverableElements"
        />
    </div>
</template>

<script setup>
// template refs
const main = ref(null);

const { t } = useI18n();

const i18nHead = useLocaleHead({
    addDirAttribute: true,
    addSeoAttributes: true
});

const route = useRoute();

useHead({
    htmlAttrs: {
        ...i18nHead.value.htmlAttrs
    }
});

const hoverableElements = ref([
    {
        class: 'custom-cursor--hovering',
        elements: 'a, button, summary'
    },
    {
        class: 'custom-cursor--disabled',
        elements: 'iframe'
    }
]);

onMounted(() => {
    setTimeout(() => {
        document.documentElement.classList.add('layout-loaded');
    }, 1200);
});

watch(() => route.path, () => {
    main.value?.focus();
});

const mainStore = useMainStore();
const showNewsletterSubscribe = computed(() => mainStore.newsLetterSubscribeIsVisible);
</script>

<style lang="less">
.layout {
    display: grid;
    grid-template-areas: 'header' 'main' 'footer';
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
}

.layout__skiplink {
    .focus-ball-bottom();
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    height: var(--menu-bar-height-mobile);

    &:focus {
        position: fixed;
        z-index: 99999;
    }

    &:not(:focus) {
        pointer-events: none;
        opacity: 0;
        z-index: -1;
    }

    @media @q-md-min {
        height: var(--menu-bar-height-desktop);
    }
}

.layout__main {
    position: relative;
    min-height: 100vh;

    @media @q-md-min {
        min-height: 100vh;
    }
}

.layout__header {
    grid-area: header;
    position: sticky;
    top: 0;
    z-index: 100;
    pointer-events: none;

    > * {
        pointer-events: all;
    }
}

.layout__footer {
    grid-area: footer;
}

.section__footer {
    transition: 300ms;
    margin-top: 3rem;
    border-top: 1px solid var(--color-darkest);

    @media @q-md-min {
        margin-top: 4rem;
    }
}

</style>
